// Content Aside
@keyframes animate-fade{
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.nk-aside{
    position: fixed;
    top: 0;
    left: 0;
    border-right: 1px solid $border-light;
    transform: translateX(-100%);
    background:$aside-bg;
    min-height: 100vh;
    max-height: 100vh;
    z-index: 700;
    width: 280px;
    padding-top: $header-height + 10;
    &.mobile-menu{
        transition: transform .4s ease;
    }
    &.content-active{
        transform: none;
    }
    + .toggle-overlay{
        z-index: 600;
    }
    &-inner{
        padding-left: $sidebar-gap-x;
        padding-right: $sidebar-gap-x;
    }
    &-header{
        display: none;
    }
    &-close{
        display: none;
    }
    .nk-sidebar-menu{
        max-height: calc(100vh - (#{$header-height} + #{$content-gap-y}));
        padding-bottom: 2.5rem;
    }
}

.has-aside {
    .nk-content{
        padding-bottom: 0;
        &-wrap{
            padding-bottom: 2rem;
        }
        &-body{
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - #{$aside-content-body-height-calc});
        }
    }
    .nk-footer{
        background: transparent;
        padding-top: 0;
        border-top: 0;
        padding-left: 0;
        padding-right: 0;
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.apps-only {
    .nk-content{
        padding-top: 1.5rem;
        padding-bottom: 0;
        &-wrap{
            padding-bottom: 1.5rem;
        }
    }
    .nk-footer{
        background: transparent;
        border-top: none;
        padding-left: 0;
        padding-right: 0;
        margin-left: -14px;
        margin-right: -14px;
    }
}
@include media-breakpoint-between(sm,xl){
    .nk-aside{
        width: 300px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

@include media-breakpoint-up(lg){
    .has-aside {
        .nk-content-inner{
            display: flex;
        }
        .nk-content-body{
            padding: 2rem 0 0;
            width: calc(100% - (220px + 48px))
        }
    }
    .nk-aside{
        padding: 2rem 0;
        width: 220px;
        flex-shrink: 0;
        position: static;
        background: transparent;
        opacity: 1;
        visibility: visible;
        margin-right: 48px;
        border:none;
        transform: none;
        max-height: none;
        min-height: none;
        &-inner{
            padding-left: 0;
            padding-right: 0;
        }
        .nk-sidebar-menu{
            max-height: none;
        }
        + .toggle-overlay{
            display: none;
        }
    }
    .apps-only{
        .nk-aside{
            display: none;
        }
        .nk-content{
            padding-top:  0;
            &-body{
                padding: 1.5rem 0 0;
                width: 100%;
            }
        }
    }
}
@include media-breakpoint-down(xl){
    .nk-aside{
        .nk-menu-main + .nk-menu{
            margin-top: 1.5rem;
        }
    }
}