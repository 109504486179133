// Download page
$pdl-margin:   1rem;

$pdl-item-gap-x: 1.25rem;
$pdl-item-gap-y: 1rem;

$pdl-item-gap-x-desk: 1.5rem;
$pdl-item-gap-y-desk: 1rem;

$pdl-icon-width: 1.75rem;
$pdl-icon-width-desk: 2.5rem;

// Invoice Page
$invoice-wrap-xy: 1.25rem;
$invoice-wrap-xy-desk: 3rem;
$invoice-margin-bottom: 1.5rem;