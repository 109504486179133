// Widget
///////////////////////
.widget{
    &-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
        > *{
            margin-bottom: 0;
        } 
        .text-center & {
            justify-content: center;
        }
        @if($dark_theme_option==true) {
            a:hover {
                .is-theme & {
                    color: $white;
                }
            }
        }
    }
}

// Side Widget
///////////////////////
.side-wg{
    &:not(:last-child){
        margin-bottom: 2.5rem;
    }
    &-title {
        padding-bottom: 0.75rem;
    }
    .back-to {
        font-size: 0.875rem;
        line-height: 1.1;
        font-weight: $fw-normal;
        position: relative;
        color: $base-light;
        display: inline-flex;
        align-items: center;
        .icon {
            font-size: 1.25rem;
            width: 1.75rem;
            margin-top: -3px;
            display: inline-block;
        }
    }
}


/// Widget CHART
///////////////////////////
.nk-ck{
    height: 260px;
    &-sm{
        height: 180px;
    }
}
.nk-ck1{
    height: 120px;
}
.nk-ck2{
    height: 240px;
}
.nk-ck3{
    height: 160px;
}
.nk-cktv{
    height: 300px;
    overflow: hidden;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    .tradingview-widget-container {
        overflow: hidden;
        position: relative;
        top: 8px;
        > div {
            margin: -1px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .nk-ck{
        height: 260px;
        &-sm{
            height: 180px;
        }
    }
    .nk-ck1{
        height: 120px;
    }
    .nk-ck2{
        height: 240px;
    }
    .nk-ck3{
        height: 258px;
    }
}

// WG 6
///////////////////////
.nk-wg6{
    &-title{
        &:not(:first-child){
            margin-top: 1.5rem;
        }
        &:not(:last-child){
            margin-bottom: 1.5rem;
        }
    }
    &-text{
        &:not(:last-child){
            margin-bottom: 1.5rem;
        }
    }
}


// ASide WG
///////////////////////
.aside-wg{
    + .aside-wg{
        padding-top: 2rem;
    }
}