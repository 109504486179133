.toast{
    .close{
        display: flex;
        border: none;
        background: transparent;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: $black;
        text-shadow: 0 1px 0 #fff;
        opacity: .5;
        margin-right: -0.5rem;
        margin-left: 0.5rem;
    }
    &:not(:last-child) {
        margin-bottom: 0.75rem;
    }
}