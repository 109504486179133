.align-start{
    @extend .d-flex;
    @extend .align-items-start;
}
.align-end{
    @extend .d-flex;
    @extend .align-items-end;
}
.align-center{
    @extend .d-flex;
    @extend .align-items-center;
}
.justify-start{
    @extend .d-flex;
    @extend .justify-content-start;
}
.justify-end{
    @extend .d-flex;
    @extend .justify-content-end;
}
.justify-center{
    @extend .d-flex;
    @extend .justify-content-center;
}
.justify-between{
    @extend .d-flex;
    @extend .justify-content-between;
}
.justify-around{
    @extend .d-flex;
    @extend .justify-content-around;
}
.between-start{
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-start;
}
.between-center{
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
}
.center{
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
}
.stretch{
    @extend .d-flex;
    @extend .align-items-stretch;
}
.shrink-0{
    flex-shrink: 0;
}