$icomoon-font-family: "Nioicon" !default;
$icomoon-font-path: $nk-dashlite-font-url !default;

$ni-bugs-alt-fill: "\ecf9";
$ni-bugs-alt: "\ecfa";
$ni-bulb-alt-fill: "\ecfb";
$ni-bulb-alt: "\ecfc";
$ni-chat-msg-fill: "\ecfd";
$ni-chat-msg: "\ecfe";
$ni-diamond-fill: "\ecff";
$ni-diamond: "\ed00";
$ni-file-code-fill: "\ed01";
$ni-google-play-store-alt: "\ed02";
$ni-laptop-fill: "\ed03";
$ni-mail-alt: "\ed04";
$ni-newspaper-fill: "\ed05";
$ni-newspaper: "\ed06";
$ni-pie-2-fill: "\ed07";
$ni-pie-2: "\ed08";
$ni-presentation-fill: "\ed09";
$ni-presentation: "\ed0a";
$ni-projector-screen-fill: "\ed0b";
$ni-projector-screen: "\ed0c";
$ni-quote-sq-left-fill: "\ed0d";
$ni-quote-sq-left: "\ed0e";
$ni-quote-sq-right-fill: "\ed0f";
$ni-quote-sq-right: "\ed10";
$ni-send-fill: "\ed11";
$ni-setting-question-fill: "\ed12";
$ni-setting-question: "\ed13";
$ni-support-fill: "\ed14";
$ni-support: "\ed15";
$ni-traffic-signal-fill: "\ed16";
$ni-traffic-signal: "\ed17";
$ni-tree-structure-fill: "\ed18";
$ni-tree-structure: "\ed19";
$ni-trophy-fill: "\ed1a";
$ni-trophy: "\ed1b";
$ni-user-group-fill: "\ed1c";
$ni-user-group: "\ed1d";
$ni-user-switch-fill: "\ed1e";
$ni-user-switch: "\ed1f";
$ni-nioboard: "\ecf2";
$ni-graphql: "\ecf3";
$ni-node-js: "\ecf4";
$ni-node: "\ecf5";
$ni-svelte: "\ecf6";
$ni-typescript: "\ecf7";
$ni-vue: "\ecf8";
$ni-centos: "\ece6";
$ni-covid: "\ece7";
$ni-fedora: "\ece8";
$ni-hot-fill: "\ece9";
$ni-hot: "\ecea";
$ni-linux-server: "\eceb";
$ni-linux: "\ecec";
$ni-note-add-fill: "\eced";
$ni-repeat-fill: "\ecee";
$ni-tranx-fill: "\ecef";
$ni-ubuntu: "\ecf0";
$ni-virus: "\ecf1";
$ni-b-chrome: "\ec63";
$ni-b-edge: "\ec64";
$ni-b-firefox: "\ec94";
$ni-b-ie: "\ec95";
$ni-b-opera: "\ec96";
$ni-b-safari: "\ec97";
$ni-b-si: "\ec98";
$ni-b-uc: "\ec99";
$ni-brick-fill: "\ec9a";
$ni-brick: "\ec9b";
$ni-col-3s: "\ec9c";
$ni-col-4s: "\ec9d";
$ni-col-2s: "\ec9e";
$ni-comments: "\ec9f";
$ni-dot-sq: "\eca0";
$ni-dot: "\eca1";
$ni-footer: "\eca2";
$ni-header: "\eca3";
$ni-heading: "\eca4";
$ni-layout-alt-fill: "\eca5";
$ni-layout-alt: "\eca6";
$ni-layout-fill1: "\eca7";
$ni-layout1: "\eca8";
$ni-list-index-fill: "\eca9";
$ni-list-index: "\ecaa";
$ni-list-thumb-alt-fill: "\ecab";
$ni-list-thumb-alt: "\ecac";
$ni-list-thumb-fill: "\ecad";
$ni-list-thumb: "\ecae";
$ni-masonry-fill: "\ecaf";
$ni-masonry: "\ecb0";
$ni-menu-circled: "\ecb1";
$ni-menu-squared: "\ecb2";
$ni-notice: "\ecb3";
$ni-pen2: "\ecb4";
$ni-propert-blank: "\ecb5";
$ni-property-add: "\ecb6";
$ni-property-alt: "\ecb7";
$ni-property-remove: "\ecb8";
$ni-property: "\ecb9";
$ni-puzzle-fill: "\ecba";
$ni-puzzle: "\ecbb";
$ni-quote-left: "\ecbc";
$ni-quote-right: "\ecbd";
$ni-row-mix: "\ecbe";
$ni-row-view1: "\ecbf";
$ni-sidebar-r: "\ecc0";
$ni-text2: "\ecc1";
$ni-tile-thumb-fill: "\ecc2";
$ni-tile-thumb: "\ecc3";
$ni-view-col-fill: "\ecc4";
$ni-view-col-sq: "\ecc5";
$ni-view-col: "\ecc6";
$ni-view-col2: "\ecc7";
$ni-view-col3: "\ecc8";
$ni-view-cols-fill: "\ecc9";
$ni-view-cols-sq: "\ecca";
$ni-view-cols: "\eccb";
$ni-view-grid-fill: "\eccc";
$ni-view-grid-sq: "\eccd";
$ni-view-grid-wd: "\ecce";
$ni-view-grid: "\eccf";
$ni-view-grid2-wd: "\ecd0";
$ni-view-grid3-wd: "\ecd1";
$ni-view-group-fill: "\ecd2";
$ni-view-group-wd: "\ecd3";
$ni-view-list-fill: "\ecd4";
$ni-view-list-sq: "\ecd5";
$ni-view-list-wd: "\ecd6";
$ni-view-list: "\ecd7";
$ni-view-panel-fill: "\ecd8";
$ni-view-panel-sq: "\ecd9";
$ni-view-panel: "\ecda";
$ni-view-row-fill: "\ecdb";
$ni-view-row-sq: "\ecdc";
$ni-view-row-wd: "\ecdd";
$ni-view-row: "\ecde";
$ni-view-x1: "\ecdf";
$ni-view-x2: "\ece0";
$ni-view-x3: "\ece1";
$ni-view-x4: "\ece2";
$ni-view-x5: "\ece3";
$ni-view-x6: "\ece4";
$ni-view-x7: "\ece5";
$ni-dashlite: "\eb91";
$ni-dashlite-circle: "\ec56";
$ni-dashlite-alt: "\ec57";
$ni-master-card: "\ec58";
$ni-paypal: "\ec59";
$ni-visa-alt: "\ec5a";
$ni-coin-eur: "\ec5b";
$ni-coin-gbp: "\ec5c";
$ni-sign-ada-alt: "\ec5d";
$ni-sign-bch-alt: "\ec5e";
$ni-sign-bgp-alt: "\ec5f";
$ni-sign-bnb-alt: "\ec60";
$ni-sign-brl-alt: "\ec61";
$ni-sign-btc-alt: "\ec62";
$ni-sign-cc-alt: "\ec65";
$ni-sign-cc-alt2: "\ec66";
$ni-sign-chf-alt: "\ec67";
$ni-sign-cny-alt: "\ec68";
$ni-sign-czk-alt: "\ec69";
$ni-sign-dash-alt: "\ec6a";
$ni-sign-dkk-alt: "\ec6b";
$ni-sign-eos-alt: "\ec6c";
$ni-sign-eth-alt: "\ec6d";
$ni-sign-eur-alt2: "\ec6e";
$ni-sign-euro-alt: "\ec6f";
$ni-sign-gbp-alt2: "\ec70";
$ni-sign-hkd-alt: "\ec71";
$ni-sign-idr-alt: "\ec72";
$ni-sign-inr-alt: "\ec73";
$ni-sign-jpy-alt: "\ec74";
$ni-sign-kr-alt: "\ec75";
$ni-sign-ltc-alt: "\ec76";
$ni-sign-ltc: "\ec77";
$ni-sign-mxn-alt: "\ec78";
$ni-sign-mxr-alt: "\ec79";
$ni-sign-myr-alt: "\ec7a";
$ni-sign-paypal-alt: "\ec7b";
$ni-sign-paypal-full: "\ec7c";
$ni-sign-php-alt: "\ec7d";
$ni-sign-pln-alt: "\ec7e";
$ni-sign-rub-alt: "\ec7f";
$ni-sign-sek-alt: "\ec80";
$ni-sign-sgd-alt: "\ec81";
$ni-sign-kobo-alt: "\ec82";
$ni-sign-steem-alt: "\ec83";
$ni-sign-steller-alt: "\ec84";
$ni-sign-stripe-fulll: "\ec85";
$ni-sign-thb-alt: "\ec86";
$ni-sign-trx-alt: "\ec87";
$ni-sign-try-alt: "\ec88";
$ni-sign-usd-alt: "\ec89";
$ni-sign-usd-alt2: "\ec8a";
$ni-sign-usdc-alt: "\ec8b";
$ni-sign-usdt-alt: "\ec8c";
$ni-sign-visa-alt: "\ec8d";
$ni-sign-vnd-alt: "\ec8e";
$ni-sign-waves-alt: "\ec8f";
$ni-sign-xem-alt: "\ec90";
$ni-sign-xrp-new-alt: "\ec91";
$ni-sign-xrp-old-alt: "\ec92";
$ni-sign-zcash-alt: "\ec93";
$ni-chevron-left: "\e9cc";
$ni-chevron-right: "\e9d3";
$ni-chevron-up: "\e9da";
$ni-chevron-down: "\e9c5";
$ni-chevron-left-round: "\e9cb";
$ni-chevron-right-round: "\e9d2";
$ni-chevron-up-round: "\e9d9";
$ni-chevron-down-round: "\e9c4";
$ni-chevron-left-round-fill: "\e9ca";
$ni-chevron-right-round-fill: "\e9d1";
$ni-chevron-up-round-fill: "\e9d8";
$ni-chevron-down-round-fill: "\e9c3";
$ni-chevron-left-c: "\e9c6";
$ni-chevron-right-c: "\e9cd";
$ni-chevron-up-c: "\e9d4";
$ni-chevron-down-c: "\e9bf";
$ni-chevron-left-fill-c: "\e9c9";
$ni-chevron-right-fill-c: "\e9d0";
$ni-chevron-up-fill-c: "\e9d7";
$ni-chevron-down-fill-c: "\e9c2";
$ni-chevron-left-circle: "\e9c8";
$ni-chevron-right-circle: "\e9cf";
$ni-chevron-up-circle: "\e9d6";
$ni-chevron-down-circle: "\e9c1";
$ni-chevron-left-circle-fill: "\e9c7";
$ni-chevron-right-circle-fill: "\e9ce";
$ni-chevron-up-circle-fill: "\e9d5";
$ni-chevron-down-circle-fill: "\e9c0";
$ni-caret-left: "\e99b";
$ni-caret-right: "\e99d";
$ni-caret-up: "\e99f";
$ni-caret-down: "\e999";
$ni-caret-left-fill: "\e99a";
$ni-caret-right-fill: "\e99c";
$ni-caret-up-fill: "\e99e";
$ni-caret-down-fill: "\e998";
$ni-sort: "\ebbf";
$ni-sort-up: "\ebbd";
$ni-sort-down: "\ebb9";
$ni-sort-fill: "\ebba";
$ni-sort-up-fill: "\ebbc";
$ni-sort-down-fill: "\ebb8";
$ni-sort-v: "\ebbe";
$ni-swap-v: "\ebdc";
$ni-swap: "\ebdd";
$ni-arrow-left-round: "\e937";
$ni-arrow-right-round: "\e942";
$ni-arrow-up-round: "\e94f";
$ni-arrow-down-round: "\e92c";
$ni-arrow-left-round-fill: "\e936";
$ni-arrow-right-round-fill: "\e941";
$ni-arrow-up-round-fill: "\e94e";
$ni-arrow-down-round-fill: "\e92b";
$ni-arrow-left-c: "\e932";
$ni-arrow-right-c: "\e93d";
$ni-arrow-up-c: "\e948";
$ni-arrow-down-c: "\e925";
$ni-arrow-left-fill-c: "\e935";
$ni-arrow-right-fill-c: "\e940";
$ni-arrow-up-fill-c: "\e94b";
$ni-arrow-down-fill-c: "\e928";
$ni-arrow-left-circle: "\e934";
$ni-arrow-right-circle: "\e93f";
$ni-arrow-up-circle: "\e94a";
$ni-arrow-down-circle: "\e927";
$ni-arrow-left-circle-fill: "\e933";
$ni-arrow-up-circle-fill: "\e949";
$ni-arrow-down-circle-fill: "\e926";
$ni-arrow-right-circle-fill: "\e93e";
$ni-chevrons-left: "\e9dc";
$ni-chevrons-right: "\e9dd";
$ni-chevrons-up: "\e9de";
$ni-chevrons-down: "\e9db";
$ni-first: "\ea59";
$ni-last: "\eab8";
$ni-back-ios: "\e958";
$ni-forward-ios: "\ea6d";
$ni-upword-ios: "\ec20";
$ni-downward-ios: "\ea23";
$ni-back-alt: "\e955";
$ni-forward-alt: "\ea69";
$ni-upword-alt: "\ec1f";
$ni-downward-alt: "\ea22";
$ni-back-alt-fill: "\e954";
$ni-forward-alt-fill: "\ea68";
$ni-upword-alt-fill: "\ec1e";
$ni-downward-alt-fill: "\ea21";
$ni-arrow-long-left: "\e93a";
$ni-arrow-long-right: "\e93b";
$ni-arrow-long-up: "\e93c";
$ni-arrow-long-down: "\e939";
$ni-arrow-left: "\e938";
$ni-arrow-right: "\e943";
$ni-arrow-up: "\e950";
$ni-arrow-down: "\e92d";
$ni-arrow-up-left: "\e94c";
$ni-arrow-up-right: "\e94d";
$ni-arrow-down-left: "\e929";
$ni-arrow-down-right: "\e92a";
$ni-arrow-to-left: "\e945";
$ni-arrow-to-right: "\e946";
$ni-arrow-to-up: "\e947";
$ni-arrow-to-down: "\e944";
$ni-arrow-from-left: "\e92f";
$ni-arrow-from-right: "\e930";
$ni-arrow-from-up: "\e931";
$ni-arrow-from-down: "\e92e";
$ni-curve-down-left: "\ea0b";
$ni-curve-up-right: "\ea12";
$ni-curve-up-left: "\ea11";
$ni-curve-down-right: "\ea0c";
$ni-curve-left-up: "\ea0e";
$ni-curve-right-up: "\ea10";
$ni-curve-left-down: "\ea0d";
$ni-curve-right-down: "\ea0f";
$ni-back-arrow: "\e957";
$ni-forward-arrow: "\ea6b";
$ni-back-arrow-fill: "\e956";
$ni-forward-arrow-fill: "\ea6a";
$ni-navigate: "\eb0f";
$ni-navigate-up: "\eb0e";
$ni-navigate-fill: "\eb0c";
$ni-navigate-up-fill: "\eb0d";
$ni-send: "\eb6f";
$ni-send-alt: "\eb6e";
$ni-unfold-less: "\ec15";
$ni-unfold-more: "\ec16";
$ni-exchange-v: "\ea32";
$ni-exchange: "\ea33";
$ni-expand: "\ea34";
$ni-shrink: "\eb83";
$ni-focus: "\ea5e";
$ni-maximize: "\eadf";
$ni-minimize: "\eaf2";
$ni-maximize-alt: "\eade";
$ni-minimize-alt: "\eaf1";
$ni-shuffle: "\eb84";
$ni-cross-sm: "\ea05";
$ni-cross: "\ea06";
$ni-cross-round: "\ea04";
$ni-cross-circle: "\ea01";
$ni-cross-c: "\e9ff";
$ni-cross-round-fill: "\ea03";
$ni-cross-circle-fill: "\ea00";
$ni-cross-fill-c: "\ea02";
$ni-na: "\eb0b";
$ni-check: "\e9be";
$ni-check-thick: "\e9bd";
$ni-done: "\ea1c";
$ni-check-round: "\e9bc";
$ni-check-circle: "\e9b8";
$ni-check-c: "\e9b5";
$ni-check-round-fill: "\e9bb";
$ni-check-circle-fill: "\e9b7";
$ni-check-fill-c: "\e9b9";
$ni-check-circle-cut: "\e9b6";
$ni-check-round-cut: "\e9ba";
$ni-bullet: "\e981";
$ni-circle: "\e9e0";
$ni-square: "\ebca";
$ni-square-c: "\ebc7";
$ni-bullet-fill: "\e980";
$ni-circle-fill: "\e9df";
$ni-square-fill: "\ebc9";
$ni-square-fill-c: "\ebc8";
$ni-plus-sm: "\eb43";
$ni-minus-sm: "\eaf9";
$ni-plus: "\eb44";
$ni-minus: "\eafa";
$ni-plus-round: "\eb42";
$ni-minus-round: "\eaf8";
$ni-plus-circle: "\eb3d";
$ni-minus-circle: "\eaf5";
$ni-plus-c: "\eb3b";
$ni-minus-c: "\eaf3";
$ni-plus-round-fill: "\eb41";
$ni-plus-circle-fill: "\eb3c";
$ni-minus-round-fill: "\eaf7";
$ni-minus-circle-fill: "\eaf4";
$ni-plus-fill-c: "\eb3e";
$ni-minus-fill-c: "\eaf6";
$ni-plus-medi: "\eb40";
$ni-plus-medi-fill: "\eb3f";
$ni-equal-sm: "\ea30";
$ni-equal: "\ea31";
$ni-calc: "\e982";
$ni-search: "\eb6c";
$ni-zoom-out: "\ec55";
$ni-zoom-in: "\ec54";
$ni-play: "\eb3a";
$ni-play-fill: "\eb39";
$ni-play-circle: "\eb38";
$ni-play-circle-fill: "\eb37";
$ni-pause: "\eb26";
$ni-pause-fill: "\eb25";
$ni-pause-circle: "\eb24";
$ni-pause-circle-fill: "\eb23";
$ni-stop: "\ebd6";
$ni-stop-fill: "\ebd5";
$ni-stop-circle: "\ebd4";
$ni-stop-circle-fill: "\ebd3";
$ni-rewind: "\eb62";
$ni-forward: "\ea6e";
$ni-rewind-fill: "\eb61";
$ni-forward-fill: "\ea6c";
$ni-step-back: "\ebd1";
$ni-step-forward: "\ebd2";
$ni-vol-off: "\ec40";
$ni-vol-no: "\ec3f";
$ni-vol-half: "\ec3e";
$ni-vol: "\ec41";
$ni-mic: "\eaef";
$ni-mic-off: "\eaee";
$ni-video: "\ec39";
$ni-video-off: "\ec38";
$ni-video-fill: "\ec37";
$ni-loader: "\ead2";
$ni-power: "\eb47";
$ni-signout: "\ebb2";
$ni-signin: "\ebb1";
$ni-upload: "\ec1d";
$ni-download: "\ea20";
$ni-alert-circle: "\e90d";
$ni-alert: "\e910";
$ni-caution: "\e9a3";
$ni-report: "\eb5e";
$ni-alert-c: "\e90b";
$ni-alert-circle-fill: "\e90c";
$ni-alert-fill: "\e90f";
$ni-caution-fill: "\e9a2";
$ni-report-fill: "\eb5c";
$ni-alert-fill-c: "\e90e";
$ni-info-i: "\eaa8";
$ni-info: "\eaa9";
$ni-info-fill: "\eaa7";
$ni-help: "\ea98";
$ni-help-fill: "\ea97";
$ni-archived: "\e924";
$ni-archive: "\e922";
$ni-unarchive: "\ec12";
$ni-archived-fill: "\e923";
$ni-archive-fill: "\e921";
$ni-unarchive-fill: "\ec11";
$ni-bag: "\e95a";
$ni-bag-fill: "\e959";
$ni-bell: "\e965";
$ni-bell-off: "\e964";
$ni-bell-fill: "\e962";
$ni-bell-off-fill: "\e963";
$ni-wifi: "\ec4b";
$ni-wifi-off: "\ec4a";
$ni-live: "\ead1";
$ni-signal: "\ebb0";
$ni-bluetooth: "\e96d";
$ni-blank-alt: "\e967";
$ni-blank: "\e968";
$ni-blankf-fill: "\e969";
$ni-block-over: "\e96a";
$ni-book-read: "\e970";
$ni-book: "\e971";
$ni-book-fill: "\e96f";
$ni-bulb-fill: "\e97e";
$ni-bulb: "\e97f";
$ni-calendar-alt-fill: "\e983";
$ni-calendar-alt: "\e984";
$ni-calendar-booking-fill: "\e985";
$ni-calendar-booking: "\e986";
$ni-calendar-check-fill: "\e987";
$ni-calendar-check: "\e988";
$ni-calendar-fill: "\e989";
$ni-calendar: "\e98a";
$ni-calender-date-fill: "\e98b";
$ni-calender-date: "\e98c";
$ni-call: "\e990";
$ni-call-alt: "\e98e";
$ni-call-alt-fill: "\e98d";
$ni-call-fill: "\e98f";
$ni-camera-fill: "\e991";
$ni-camera: "\e992";
$ni-capsule: "\e994";
$ni-capsule-fill: "\e993";
$ni-cards: "\e997";
$ni-cards-fill: "\e996";
$ni-cart: "\e9a1";
$ni-cart-fill: "\e9a0";
$ni-cc: "\e9ae";
$ni-cc-alt: "\e9a5";
$ni-cc-alt2: "\e9a7";
$ni-cc-secure: "\e9ac";
$ni-cc-new: "\e9a9";
$ni-cc-off: "\e9aa";
$ni-cc-fill: "\e9a8";
$ni-cc-alt-fill: "\e9a4";
$ni-cc-alt2-fill: "\e9a6";
$ni-cc-secure-fill: "\e9ab";
$ni-msg-circle: "\eb07";
$ni-chat-circle: "\e9b2";
$ni-msg: "\eb09";
$ni-chat: "\e9b4";
$ni-question-alt: "\eb4b";
$ni-question: "\eb4c";
$ni-msg-circle-fill: "\eb06";
$ni-chat-circle-fill: "\e9b1";
$ni-msg-fill: "\eb08";
$ni-chat-fill: "\e9b3";
$ni-clip-h: "\e9e1";
$ni-clip-v: "\e9e2";
$ni-clip: "\e9e3";
$ni-link-alt: "\eac5";
$ni-unlink: "\ec18";
$ni-unlink-alt: "\ec17";
$ni-link-h: "\eac7";
$ni-link-v: "\eac8";
$ni-link: "\eac9";
$ni-clipboard: "\e9e7";
$ni-clipboad-check: "\e9e5";
$ni-clipboard-fill: "\e9e6";
$ni-clipboad-check-fill: "\e9e4";
$ni-clock: "\e9e9";
$ni-clock-fill: "\e9e8";
$ni-cloud: "\e9eb";
$ni-upload-cloud: "\ec1c";
$ni-download-cloud: "\ea1f";
$ni-cloud-fill: "\e9ea";
$ni-contact: "\e9f9";
$ni-contact-fill: "\e9f8";
$ni-coffee: "\e9f0";
$ni-coffee-fill: "\e9ef";
$ni-box-view: "\e976";
$ni-col-view: "\e9f5";
$ni-sidebar: "\eb86";
$ni-layout: "\eabe";
$ni-table-view: "\ebdf";
$ni-layout2: "\eabf";
$ni-row-view: "\eb63";
$ni-dot-box: "\ea1e";
$ni-layout-fill: "\eabd";
$ni-box-view-fill: "\e975";
$ni-sidebar-fill: "\eb85";
$ni-table-view-fill: "\ebde";
$ni-dot-box-fill: "\ea1d";
$ni-template: "\ebee";
$ni-browser: "\e97a";
$ni-toolbar: "\ec02";
$ni-browser-fill: "\e979";
$ni-toolbar-fill: "\ec01";
$ni-template-fill: "\ebed";
$ni-box: "\e977";
$ni-package: "\eb20";
$ni-layer: "\eaba";
$ni-layers: "\eabc";
$ni-panel: "\eb22";
$ni-server: "\eb71";
$ni-layer-fill: "\eab9";
$ni-layers-fill: "\eabb";
$ni-package-fill: "\eb1f";
$ni-panel-fill: "\eb21";
$ni-server-fill: "\eb70";
$ni-color-palette: "\e9f7";
$ni-color-palette-fill: "\e9f6";
$ni-copy: "\e9fb";
$ni-copy-fill: "\e9fa";
$ni-crop-alt: "\e9fd";
$ni-crop: "\e9fe";
$ni-target: "\ebe7";
$ni-crosshair: "\ea08";
$ni-crosshair-fill: "\ea07";
$ni-db-fill: "\ea15";
$ni-db: "\ea16";
$ni-hard-drive: "\ea90";
$ni-cpu: "\e9fc";
$ni-disk: "\ea1b";
$ni-pen: "\eb2b";
$ni-edit-alt: "\ea29";
$ni-pen-fill: "\eb2a";
$ni-edit-alt-fill: "\ea28";
$ni-pen-alt-fill: "\eb29";
$ni-edit-fill: "\ea2a";
$ni-edit: "\ea2b";
$ni-external-alt: "\ea35";
$ni-external: "\ea36";
$ni-eye-alt: "\ea38";
$ni-eye-alt-fill: "\ea37";
$ni-eye: "\ea3c";
$ni-eye-fill: "\ea39";
$ni-eye-off: "\ea3b";
$ni-eye-off-fill: "\ea3a";
$ni-file: "\ea53";
$ni-file-minus: "\ea49";
$ni-file-plus: "\ea4c";
$ni-file-remove: "\ea4e";
$ni-file-check: "\ea41";
$ni-file-code: "\ea42";
$ni-file-docs: "\ea44";
$ni-file-img: "\ea47";
$ni-file-doc: "\ea43";
$ni-file-pdf: "\ea4a";
$ni-file-xls: "\ea51";
$ni-file-zip: "\ea52";
$ni-file-download: "\ea45";
$ni-file-text: "\ea50";
$ni-files: "\ea55";
$ni-file-fill: "\ea46";
$ni-file-minus-fill: "\ea48";
$ni-file-plus-fill: "\ea4b";
$ni-file-remove-fill: "\ea4d";
$ni-file-check-fill: "\ea40";
$ni-file-text-fill: "\ea4f";
$ni-files-fill: "\ea54";
$ni-folder: "\ea65";
$ni-folder-minus: "\ea62";
$ni-folder-plus: "\ea63";
$ni-folder-remove: "\ea64";
$ni-folder-check: "\ea5f";
$ni-folder-list: "\ea61";
$ni-folders: "\ea67";
$ni-folder-fill: "\ea60";
$ni-folders-fill: "\ea66";
$ni-filter-alt: "\ea56";
$ni-sort-line: "\ebbb";
$ni-filter-fill: "\ea57";
$ni-filter: "\ea58";
$ni-flag: "\ea5b";
$ni-flag-fill: "\ea5a";
$ni-notify: "\eb16";
$ni-dashboard: "\ea14";
$ni-dashboard-fill: "\ea13";
$ni-grid-sq: "\ea89";
$ni-grid: "\ea8a";
$ni-grid-c: "\ea83";
$ni-grid-alt: "\ea7e";
$ni-grid-plus: "\ea88";
$ni-grid-add-c: "\ea7b";
$ni-grid-fill: "\ea85";
$ni-grid-fill-c: "\ea84";
$ni-grid-alt-fill: "\ea7d";
$ni-grid-plus-fill: "\ea87";
$ni-grid-add-fill-c: "\ea7c";
$ni-grid-box-alt-fill: "\ea7f";
$ni-grid-box-alt: "\ea80";
$ni-grid-box: "\ea82";
$ni-grid-box-fill: "\ea81";
$ni-grid-line: "\ea86";
$ni-menu-alt-left: "\eae6";
$ni-menu-alt-r: "\eae7";
$ni-menu-alt: "\eae8";
$ni-menu-center: "\eae9";
$ni-menu-left: "\eaea";
$ni-menu-right: "\eaeb";
$ni-menu: "\eaec";
$ni-trend-up: "\ec0a";
$ni-trend-down: "\ec09";
$ni-line-chart-down: "\eac2";
$ni-line-chart-up: "\eac3";
$ni-line-chart: "\eac4";
$ni-bar-chart: "\e95e";
$ni-bar-chart-alt: "\e95c";
$ni-chart-up: "\e9b0";
$ni-chart-down: "\e9af";
$ni-growth: "\ea8c";
$ni-growth-fill: "\ea8b";
$ni-bar-chart-fill: "\e95d";
$ni-bar-c: "\e95b";
$ni-bar-fill-c: "\e95f";
$ni-pie: "\eb30";
$ni-pie-alt: "\eb2e";
$ni-pie-fill: "\eb2f";
$ni-activity: "\e906";
$ni-activity-alt: "\e903";
$ni-activity-round: "\e905";
$ni-activity-round-fill: "\e904";
$ni-meter: "\eaed";
$ni-speed: "\ebc5";
$ni-happy: "\ea8e";
$ni-sad: "\eb66";
$ni-meh: "\eae5";
$ni-happyf-fill: "\ea8f";
$ni-sad-fill: "\eb65";
$ni-meh-fill: "\eae4";
$ni-home: "\ea9c";
$ni-home-alt: "\ea9a";
$ni-home-fill: "\ea9b";
$ni-img: "\eaa0";
$ni-img-fill: "\ea9f";
$ni-inbox: "\eaa6";
$ni-inbox-in: "\eaa3";
$ni-inbox-out: "\eaa5";
$ni-inbox-fill: "\eaa1";
$ni-inbox-in-fill: "\eaa2";
$ni-inbox-out-fill: "\eaa4";
$ni-link-group: "\eac6";
$ni-lock: "\ead7";
$ni-lock-alt: "\ead5";
$ni-lock-fill: "\ead6";
$ni-lock-alt-fill: "\ead4";
$ni-unlock: "\ec1a";
$ni-unlock-fill: "\ec19";
$ni-mail: "\eada";
$ni-emails: "\ea2e";
$ni-mail-fill: "\ead9";
$ni-emails-fill: "\ea2d";
$ni-map-pin: "\eadc";
$ni-location: "\ead3";
$ni-map: "\eadd";
$ni-map-pin-fill: "\eadb";
$ni-list: "\ead0";
$ni-list-ol: "\eace";
$ni-align-center: "\e911";
$ni-align-justify: "\e912";
$ni-align-left: "\e913";
$ni-align-right: "\e914";
$ni-list-check: "\eacc";
$ni-list-round: "\eacf";
$ni-card-view: "\e995";
$ni-list-fill: "\eacd";
$ni-save: "\eb68";
$ni-save-fill: "\eb67";
$ni-move: "\eb04";
$ni-scissor: "\eb6b";
$ni-text: "\ebf2";
$ni-text-a: "\ebf0";
$ni-bold: "\e96e";
$ni-italic: "\eaad";
$ni-underline: "\ec13";
$ni-percent: "\eb2c";
$ni-at: "\e952";
$ni-hash: "\ea91";
$ni-code: "\e9ee";
$ni-code-download: "\e9ed";
$ni-terminal: "\ebef";
$ni-cmd: "\e9ec";
$ni-sun: "\ebd9";
$ni-sun-fill: "\ebd8";
$ni-moon-fill: "\eafe";
$ni-moon: "\eaff";
$ni-light: "\eac1";
$ni-light-fill: "\eac0";
$ni-more-v: "\eb03";
$ni-more-h: "\eb01";
$ni-more-h-alt: "\eb00";
$ni-more-v-alt: "\eb02";
$ni-music: "\eb0a";
$ni-movie: "\eb05";
$ni-offer: "\eb18";
$ni-offer-fill: "\eb17";
$ni-opt-alt: "\eb1a";
$ni-opt: "\eb1e";
$ni-opt-dot-alt: "\eb1b";
$ni-opt-dot: "\eb1d";
$ni-opt-dot-fill: "\eb1c";
$ni-opt-alt-fill: "\eb19";
$ni-user-alt: "\ec24";
$ni-user-alt-fill: "\ec23";
$ni-user: "\ec33";
$ni-users: "\ec35";
$ni-user-add: "\ec22";
$ni-user-remove: "\ec31";
$ni-user-check: "\ec27";
$ni-user-cross: "\ec2b";
$ni-account-setting: "\e902";
$ni-account-setting-alt: "\e900";
$ni-user-list: "\ec2f";
$ni-user-fill: "\ec2d";
$ni-users-fill: "\ec34";
$ni-user-add-fill: "\ec21";
$ni-user-remove-fill: "\ec30";
$ni-user-check-fill: "\ec26";
$ni-user-cross-fill: "\ec2a";
$ni-account-setting-fill: "\e901";
$ni-user-list-fill: "\ec2e";
$ni-user-circle: "\ec28";
$ni-user-circle-fill: "\ec29";
$ni-user-c: "\ec25";
$ni-user-fill-c: "\ec2c";
$ni-user-round: "\ec32";
$ni-printer: "\eb31";
$ni-printer-fill: "\eb48";
$ni-laptop: "\eab6";
$ni-monitor: "\eafd";
$ni-tablet: "\ebe0";
$ni-mobile: "\eafb";
$ni-undo: "\ec14";
$ni-redo: "\eb51";
$ni-reload-alt: "\eb54";
$ni-reload: "\eb55";
$ni-regen-alt: "\eb52";
$ni-regen: "\eb53";
$ni-invest: "\eaac";
$ni-history: "\ea99";
$ni-update: "\ec1b";
$ni-repeat: "\eb57";
$ni-repeat-v: "\eb56";
$ni-tranx: "\ec03";
$ni-reply-all: "\eb59";
$ni-reply: "\eb5b";
$ni-reply-fill: "\eb5a";
$ni-reply-all-fill: "\eb58";
$ni-notes: "\eb15";
$ni-note-add: "\eb13";
$ni-notes-alt: "\eb14";
$ni-article: "\e951";
$ni-text-rich: "\ebf1";
$ni-todo: "\ebfe";
$ni-report-profit: "\eb5d";
$ni-reports-alt: "\eb5f";
$ni-reports: "\eb60";
$ni-task: "\ebea";
$ni-note-add-c: "\eb11";
$ni-task-c: "\ebe8";
$ni-todo-fill: "\ebfd";
$ni-note-add-fill-c: "\eb12";
$ni-task-fill-c: "\ebe9";
$ni-scan-fill: "\eb69";
$ni-scan: "\eb6a";
$ni-qr: "\eb4a";
$ni-money: "\eafc";
$ni-coins: "\e9f4";
$ni-coin: "\e9f3";
$ni-coin-alt: "\e9f2";
$ni-coin-alt-fill: "\e9f1";
$ni-setting-alt-fill: "\eb72";
$ni-setting-alt: "\eb73";
$ni-setting-fill: "\eb74";
$ni-setting: "\eb75";
$ni-share-alt: "\eb76";
$ni-share-fill: "\eb77";
$ni-share: "\eb78";
$ni-network: "\eb10";
$ni-rss: "\eb64";
$ni-shield: "\eb82";
$ni-shield-star: "\eb81";
$ni-shield-check: "\eb7c";
$ni-shield-alert: "\eb7a";
$ni-shield-off: "\eb7f";
$ni-security: "\eb6d";
$ni-policy: "\eb46";
$ni-shield-alert-fill: "\eb79";
$ni-shield-check-fill: "\eb7b";
$ni-shield-fill: "\eb7d";
$ni-shield-half: "\eb7e";
$ni-shield-star-fill: "\eb80";
$ni-policy-fill: "\eb45";
$ni-spark: "\ebc4";
$ni-spark-off: "\ebc3";
$ni-spark-fill: "\ebc1";
$ni-spark-off-fill: "\ebc2";
$ni-wallet: "\ec47";
$ni-wallet-alt: "\ec42";
$ni-wallet-in: "\ec44";
$ni-wallet-out: "\ec45";
$ni-wallet-saving: "\ec46";
$ni-wallet-fill: "\ec43";
$ni-star: "\ebd0";
$ni-star-half: "\ebce";
$ni-star-half-fill: "\ebcd";
$ni-star-fill: "\ebcc";
$ni-star-round: "\ebcf";
$ni-heart: "\ea95";
$ni-heart-fill: "\ea94";
$ni-swap-alt-fill: "\ebda";
$ni-swap-alt: "\ebdb";
$ni-thumbs-down: "\ebf3";
$ni-thumbs-up: "\ebf4";
$ni-tag: "\ebe4";
$ni-tag-alt: "\ebe2";
$ni-tags: "\ebe6";
$ni-tag-fill: "\ebe3";
$ni-tag-alt-fill: "\ebe1";
$ni-tags-fill: "\ebe5";
$ni-bookmark: "\e973";
$ni-bookmark-fill: "\e972";
$ni-label: "\eab5";
$ni-label-fill: "\eab4";
$ni-piority: "\eb36";
$ni-piority-fill: "\eb35";
$ni-label-alt: "\eab3";
$ni-label-alt-fill: "\eab2";
$ni-ticket-alt: "\ebf6";
$ni-ticket: "\ebfc";
$ni-ticket-minus: "\ebf9";
$ni-ticket-plus: "\ebfb";
$ni-ticket-alt-fill: "\ebf5";
$ni-ticket-fill: "\ebf7";
$ni-ticket-minus-fill: "\ebf8";
$ni-ticket-plus-fill: "\ebfa";
$ni-toggle-off: "\ebff";
$ni-toggle-on: "\ec00";
$ni-trash-alt: "\ec04";
$ni-trash-empty: "\ec06";
$ni-trash: "\ec08";
$ni-trash-fill: "\ec07";
$ni-trash-empty-fill: "\ec05";
$ni-delete-fill: "\ea17";
$ni-delete: "\ea18";
$ni-alarm-alt: "\e909";
$ni-alarm: "\e90a";
$ni-bugs: "\e97b";
$ni-building: "\e97d";
$ni-building-fill: "\e97c";
$ni-headphone: "\ea93";
$ni-headphone-fill: "\ea92";
$ni-aperture: "\e91b";
$ni-help-alt: "\ea96";
$ni-award: "\e953";
$ni-briefcase: "\e978";
$ni-gift: "\ea6f";
$ni-globe: "\ea74";
$ni-umbrela: "\ec10";
$ni-truck: "\ec0b";
$ni-sign-usd: "\eba7";
$ni-sign-dollar: "\eba7";
$ni-sign-mxn: "\eb99";
$ni-sign-sgd: "\eba0";
$ni-sign-euro: "\eb94";
$ni-sign-eur: "\eb94";
$ni-sign-gbp: "\eb89";
$ni-sign-pound: "\eb89";
$ni-sign-thb: "\eba4";
$ni-sign-inr: "\eb97";
$ni-sign-jpy: "\eb98";
$ni-sign-yen: "\eb98";
$ni-sign-cny: "\eb8e";
$ni-sign-kobo: "\eba1";
$ni-sign-chf: "\eb8d";
$ni-sign-vnd: "\ebaa";
$ni-sign-php: "\eb9c";
$ni-sign-brl: "\eb8b";
$ni-sign-idr: "\eb96";
$ni-sign-czk: "\eb8f";
$ni-sign-hkd: "\eb95";
$ni-sign-kr: "\eb9f";
$ni-sign-dkk: "\eb9f";
$ni-sign-nok: "\eb9f";
$ni-sign-sek: "\eb9f";
$ni-sign-rub: "\eb9e";
$ni-sign-myr: "\eb9b";
$ni-sign-pln: "\eb9d";
$ni-sign-try: "\eba6";
$ni-sign-waves: "\ebab";
$ni-waves: "\ebab";
$ni-sign-trx: "\eba5";
$ni-tron: "\eba5";
$ni-sign-xem: "\ebac";
$ni-nem: "\ebac";
$ni-sign-mxr: "\eb9a";
$ni-monero: "\eb9a";
$ni-sign-usdc: "\eba8";
$ni-sign-steller: "\eba3";
$ni-sign-steem: "\eba2";
$ni-sign-usdt: "\eba9";
$ni-tether: "\eba9";
$ni-sign-btc: "\eb8c";
$ni-bitcoin: "\eb8c";
$ni-sign-bch: "\eb88";
$ni-bitcoin-cash: "\eb88";
$ni-sign-bnb: "\eb8a";
$ni-binance: "\eb8a";
$ni-sign-ada: "\eb87";
$ni-sign-zcash: "\ebaf";
$ni-sign-eth: "\eb93";
$ni-ethereum: "\eb93";
$ni-sign-dash: "\eb90";
$ni-dash: "\eb90";
$ni-sign-xrp-old: "\ebae";
$ni-ripple-old: "\ebae";
$ni-sign-eos: "\eb92";
$ni-eos: "\eb92";
$ni-sign-xrp: "\ebad";
$ni-ripple: "\ebad";
$ni-american-express: "\e918";
$ni-jcb: "\eaae";
$ni-cc-jcb: "\eaae";
$ni-mc: "\eae0";
$ni-cc-mc: "\eae0";
$ni-discover: "\ea1a";
$ni-cc-discover: "\ea1a";
$ni-visa: "\ec3d";
$ni-cc-visa: "\ec3d";
$ni-cc-paypal: "\eb27";
$ni-cc-stripe: "\e9ad";
$ni-amazon-pay: "\e916";
$ni-amazon-pay-fill: "\e915";
$ni-google-pay: "\ea77";
$ni-google-pay-fill: "\ea76";
$ni-apple-pay: "\e91d";
$ni-apple-pay-fill: "\e91c";
$ni-angular: "\e91a";
$ni-react: "\eb4d";
$ni-laravel: "\eab7";
$ni-html5: "\ea9e";
$ni-css3-fill: "\ea09";
$ni-css3: "\ea0a";
$ni-js: "\eab0";
$ni-php: "\eb2d";
$ni-python: "\eb49";
$ni-bootstrap: "\e974";
$ni-ebay: "\ea27";
$ni-google-wallet: "\ea79";
$ni-google-drive: "\ea75";
$ni-google-play-store: "\ea78";
$ni-android: "\e919";
$ni-blogger-fill: "\e96b";
$ni-blogger: "\e96c";
$ni-hangout: "\ea8d";
$ni-apple-store: "\e91f";
$ni-apple-store-ios: "\e91e";
$ni-stripe: "\ebd7";
$ni-apple: "\e920";
$ni-microsoft: "\eaf0";
$ni-windows: "\ec4c";
$ni-amazon: "\e917";
$ni-paypal-alt: "\eb28";
$ni-airbnb: "\e908";
$ni-adobe: "\e907";
$ni-mailchimp: "\ead8";
$ni-dropbox: "\ea26";
$ni-digital-ocean: "\ea19";
$ni-slack: "\ebb5";
$ni-slack-hash: "\ebb4";
$ni-stack-overflow: "\ebcb";
$ni-soundcloud: "\ebc0";
$ni-blackberry: "\e966";
$ni-spotify: "\ebc6";
$ni-kickstarter: "\eab1";
$ni-houzz: "\ea9d";
$ni-vine: "\ec3c";
$ni-yelp: "\ec4f";
$ni-yoast: "\ec50";
$ni-envato: "\ea2f";
$ni-wordpress: "\ec4e";
$ni-wp: "\ec4e";
$ni-wordpress-fill: "\ec4d";
$ni-elementor: "\ea2c";
$ni-joomla: "\eaaf";
$ni-megento: "\eae3";
$ni-git: "\ea70";
$ni-github: "\ea73";
$ni-github-round: "\ea72";
$ni-github-circle: "\ea71";
$ni-dribbble: "\ea25";
$ni-dribbble-round: "\ea24";
$ni-behance: "\e961";
$ni-behance-fill: "\e960";
$ni-flickr: "\ea5d";
$ni-flickr-round: "\ea5c";
$ni-medium: "\eae2";
$ni-medium-round: "\eae1";
$ni-reddit: "\eb50";
$ni-reddit-round: "\eb4f";
$ni-reddit-circle: "\eb4e";
$ni-google: "\ea7a";
$ni-facebook-f: "\ea3e";
$ni-facebook-fill: "\ea3f";
$ni-facebook-circle: "\ea3d";
$ni-instagram: "\eaab";
$ni-instagram-round: "\eaaa";
$ni-linkedin: "\eacb";
$ni-linkedin-round: "\eaca";
$ni-twitter: "\ec0f";
$ni-twitter-round: "\ec0e";
$ni-pinterest: "\eb34";
$ni-pinterest-round: "\eb33";
$ni-pinterest-circle: "\eb32";
$ni-tumblr: "\ec0d";
$ni-tumblr-round: "\ec0c";
$ni-skype: "\ebb3";
$ni-viber: "\ec36";
$ni-whatsapp: "\ec49";
$ni-whatsapp-round: "\ec48";
$ni-snapchat: "\ebb7";
$ni-snapchat-fill: "\ebb6";
$ni-telegram: "\ebec";
$ni-telegram-circle: "\ebeb";
$ni-youtube-line: "\ec52";
$ni-youtube: "\ec52";
$ni-youtube-fill: "\ec53";
$ni-youtube-round: "\ec51";
$ni-vimeo: "\ec3b";
$ni-vimeo-fill: "\ec3a";

